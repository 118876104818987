import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchAPI } from "utils";
import type { FormBuilderProp, FormFieldProp } from "types/forms";
import FormFields from "./form-fields";

interface FormBuilderProps extends FormBuilderProp {
  slug: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({ data, slug }) => {
  const names = data?.FormField?.reduce((acc: any, val: any) => {
    acc[val.name] = "";
    return acc;
  }, {});

  const {
    reset,
    resetField,
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      ...names,
      source: slug,
    },
  });

  const onSubmit: SubmitHandler<any> = (submitData) => {
    const newData = new FormData();
    const dataObj: any = {
      publishedAt: null,
      source: submitData.source,
      hooks: data?.ZapierHooks || [],
      sendEmail: data.SendEmail,
    };

    data?.FormField.map((item: FormFieldProp) => {
      if (item.Type !== "file") {
        dataObj[item.name] = submitData[item.name];
      } else if (submitData[item.name]) {
        newData.append(
          `files.${item.name}`,
          submitData[item.name],
          submitData[item.name].name
        );
      }
    });

    newData.append("data", JSON.stringify(dataObj));

    console.log(submitData);

    return fetchAPI(
      "/dynamic-form-entries/submit",
      {},
      {
        method: "post",
        headers: {},
        body: newData,
      }
    )
      .then((res) => {
        if (res.error) {
          toast.error("There was an error submitting the form!");
          return;
        }
        toast("Form submitted!");
        reset();
      })
      .catch(() => {
        toast.error("There was an error submitting the form!");
      });
  };

  return (
    <>
      {data?.CustomCSS && data?.CustomCSS !== "" && (
        <style>{data?.CustomCSS}</style>
      )}
      <div className="form--builder">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5>{data?.Title}</h5>
            <h6>{data?.Content}</h6>
            <input type="hidden" {...register("source")} />

            <div className="form--builder__grid">
              {data?.FormField &&
                data?.FormField.map((item: FormFieldProp, key: number) => (
                  <FormFields
                    item={item}
                    key={key}
                    className="form--builder__element form--builder__grid-column-full"
                    control={control}
                    errors={errors}
                    resetField={resetField}
                  />
                ))}
            </div>
            <button
              className="btn btn--fill"
              type="submit"
              disabled={isSubmitting}
            >
              {data?.SubmitText}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
